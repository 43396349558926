<!--
* @description SaaS云平台
* @fileName index.vue
* @author liulian
* @date 2023/06/29 17:57:33
-->
<template>
  <div>
    SaaS云平台
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {

    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
